<template>
  <b-container>
    <b-card style="border-left: 0.48rem solid #7367f0">
      <b-row align-v="center">
        <b-col
          cols="3"
          class="is-mobile"
        >
          <b-media>
            <b-avatar
              ref="callback"
              :src="profileFile"
              size="18vw"
              style="max-width: 200px; max-height: 200px;"
              badge-variant="invisible"
              badge-offset="-0.1em"
            >
              <template #badge>
                <b-button
                  pill
                  class="ml-4"
                  variant="primary"
                  size="md"
                  @click="$refs.InputEl.click()"
                >
                  <input
                    ref="InputEl"
                    type="file"
                    class="d-none"
                    hidden
                    @change="uploadImg"
                  >
                  <feather-icon
                    class="d-none d-sm-inline"
                    icon="CameraIcon"
                  />
                </b-button>
              </template>
            </b-avatar>
          </b-media>
        </b-col>
        <b-col>
          <div>
            <b-card-text class="my-2">
              <h3>
                {{ dataUser.nome }}
              </h3>
              <p>
                {{ dataUser.usuario_empresas.user.email }}
              </p>
              <br>
              <h5>
                <strong>Empresa:</strong> {{ dataUser.usuario_empresas.empresa.nome_fantasia }}
              </h5>
              <h5>
                <strong>Função:</strong> {{ dataUser.funcao.nome }}
              </h5>
              <h5>
                <strong>Endereço:</strong> {{ dataUser.endereco }}, {{ dataUser.cidade.nome }}
              </h5>
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardText,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import axios from '@/../axios-auth'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const InputEl = ref(null)
    const callback = ref(null)

    return {
      InputEl,
      callback,
    }
  },

  data() {
    return {
      dataUser: {},
      funcionarioId: '',
      profileFile: '',
    }
  },
  async created() {
    this.funcionarioId = localStorage.getItem('funcionarioId')
    this.getImage()
    this.getInfoFuncionario()
  },

  methods: {
    async getInfoFuncionario() {
      await axios.get(`api/v1/funcionarios/edit/${this.funcionarioId}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.dataUser = res.data.dados.funcionario
      })
    },
    async getImage() {
      await axios.get(`api/v1/users/exibirFoto/${this.funcionarioId}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.profileFile = res.data.dados.image
      })
    },
    async uploadImg(event) {
      const formData = new FormData()
      formData.append('image', event.target.files[0])
      await axios.post('api/v1/users/uploadFoto', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.$router.go()
        if (res) {
          this.getImage()
          this.$swal({
            title: 'Imagem de perfil atualizada',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        }
      })
        .catch(() => {
          this.getImage()
          this.$swal({
            title: 'ERRO',
            text: 'Algo deu errado!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>

<style>
  h5 {
    font-weight: 400;
  }
</style>
